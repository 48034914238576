import axios from 'axios';
import store from './store';
import { i18n } from './main';

const instance = axios.create({
  baseURL: 'https://roomaclass.com/maintenance_new/',
  headers: {
    'Content-Type': 'form-data',
  },
});

instance.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  const currentLanguage = i18n.locale;

  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  config.headers['Accept-Language'] = currentLanguage;

  return config;
}, error => {
  return Promise.reject(error);
});

export default instance;
