<template>
  <div class="login-page">
    <b-container>
      <b-row class="justify-content-center">
        <b-col md="6" class=" background-image d-none d-sm-block d-md-block">
          
        </b-col>
        <b-col md="6" class="d-flex flex-column align-items-center justify-content-center">
          <b-card class="login-card">
            <b-card-header class="text-center">
              <div>
                <img :src="require('@/assets/0.png')"  height="120" alt="Logo" class="">
              </div>
              <h2 class="login-title fw-bold mb-2 mt-4">
                {{ $t('message.loginTitle') }}
              </h2>
              <div>
                <p class="login-parag"> {{ $t('message.LoginToContainue') }}</p>
              </div>
            </b-card-header>
            <b-card-body>
              <b-form @submit.prevent="login" class="login-form">
                <b-form-group :label="$t('message.emailLabel')">
                  <template #label>
                    <span class="required-label">{{ $t('message.emailLabel') }}</span>
                    <span class="required-star">*</span>
                  </template>
                  <b-form-input class="form-control-sm" v-model="email" type="email" required :placeholder="$t('message.emailPlaceholder')"
                    :state="emailState" @input="validateEmail"></b-form-input>
                  <b-form-invalid-feedback v-if="emailState === false && email.length === 0">
                    <div class="error-message">{{ $t('message.emailRequiredErrorMessage') }}</div>
                  </b-form-invalid-feedback>
                  <b-form-invalid-feedback v-if="emailState === false && !isEmailValid">
                    <div class="error-message">{{ $t('message.emailInvalidFormatErrorMessage') }}</div>
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-form-group :label="$t('message.passwordLabel')">
                  <template #label>
                    <span class="required-label">{{ $t('message.passwordLabel') }}</span>
                    <span class="required-star">*</span>
                  </template>
                  <b-input-group>
                    <b-form-input class="form-control-sm" v-model="password" :type="passwordReveal ? 'text' : 'password'" required
                      :placeholder="$t('message.passwordPlaceholder')" :state="passwordState"
                      @input="validatePassword"></b-form-input>
                    <b-icon class="toggle-password" :class="[{ 'toggle-rtl': isRTL, 'toggle-ltr': !isRTL }]"
                      @click="togglePasswordVisibility" :icon="passwordReveal ? 'eye-slash' : 'eye'"></b-icon>
                    <!-- <b-input-group-append> -->
                    <!-- <b-button @click="togglePasswordVisibility" :variant="passwordReveal ? 'danger' : 'success'"> -->
                    <!-- <b-icon :icon="passwordReveal ? 'eye-slash' : 'eye'"></b-icon> -->
                    <!-- </b-button> -->
                    <!-- </b-input-group-append> -->
                  </b-input-group>
                  <div v-if="passwordState === false && password.length === 0">
                    <div class="error-message">{{ $t('message.passwordRequiredErrorMessage') }}</div>
                  </div>
                  <!-- <b-form-valid-feedback v-if="passwordState === true">Looks good!</b-form-valid-feedback> -->
                  <div v-if="passwordState === false && !isValidPassword">
                    <div class="error-message">{{ $t('message.passwordLengthErrorMessage') }}</div>
                  </div>
                  <b-form-invalid-feedback v-if="passwordState === false && password.length === 0">
                    <div class="error-message">Please enter a password</div>
                  </b-form-invalid-feedback>
                  <!-- <b-form-valid-feedback v-if="passwordState === true">Looks good!</b-form-valid-feedback> -->
                  <b-form-invalid-feedback v-if="passwordState === false && password.length < 6">
                    <div class="error-message">Password must be at least 8 characters long</div>
                  </b-form-invalid-feedback>
                </b-form-group>
                <!-- <div class="forget-password">
                  <RouterLink :to="'/' + this.$i18n.locale + '/forget-password'"> {{ $t('message.ForgetPassword') }} </RouterLink>
                </div> -->
                <b-button type="submit" variant="dark" block class="login-button btn btn-sm" style="width: -webkit-fill-available;"
                  :disabled="!passwordState">{{ $t('message.loginButton') }}</b-button>
              </b-form>

            </b-card-body>
          </b-card>
          <p  class="text-center login-footer text-gray-400">
            {{ $t('message.copyRights') }}
            <span>2024 &copy;</span>
          </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axiosInstance from '../axios-config';
import { mapMutations } from 'vuex';
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      email: '',
      password: '',
      emailState: null,
      passwordState: null,
      isEmailValid: true,
      isValidPassword: true,
      passwordReveal: false,
    };
  },
  computed: {
    isRTL() {
      return this.$i18n.locale === 'ar';
    },
    toastClass() {
      return this.isRTL ? 'toast-rtl' : 'toast-ltr';
    }
  },
  methods: {
    ...mapActions(['login']),
    async login() {
      try {
        const formData = new FormData();
        formData.append('email', this.email);
        formData.append('password', this.password);
        const response = await axiosInstance.post('company/login.php', formData);

        console.log(response.data);
        if (response.data.status === "true") {
          const userData = response.data.output;
          this.$store.dispatch('login', userData); // حفظ البيانات في Vuex

          // تخزين id المستخدم في localStorage
          localStorage.setItem('userId', userData.id);

          // عرض إشعار التوست
          this.$bvToast.toast('logged in successfully', {
            title: 'success logged in',
            variant: 'success',
            solid: true,
            toaster: 'b-toaster-bottom-left', // Position of the toast
            // autoHideDelay: 1000,
            headerClass:this.toastClass,
            bodyClass: this.toastClass,
            noAutoHide: true
          });

          // تأخير التحويل إلى الصفحة الرئيسية
          setTimeout(() => {
            this.$router.push('/');
          }, 2000); // تأخير بـ 500 ميلي ثانية (يمكن تعديل الوقت حسب الحاجة)

        } else {
          this.$bvToast.toast('Login failed, please try again', {
            title: 'Login failed',
            variant: 'danger',
            solid: true,
            toaster: 'b-toaster-bottom-left', // Position of the toast
            // autoHideDelay: 1000,
        });
        }
      } catch (error) {
        console.error('Error logging in:', error)
        this.$bvToast.toast('An error occurred while trying to log in', {
          title: 'Error',
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-bottom-left', // Position of the toast
          // autoHideDelay: 1000,
        });
      }
    },

    validateEmail() {
      if (this.email.length === 0) {
        this.emailState = false;
        this.isEmailValid = true;
      } else if (!this.validateEmailFormat(this.email)) {
        this.emailState = false;
        this.isEmailValid = false;
      } else {
        this.emailState = true;
        this.isEmailValid = true;
      }
    },
    validateEmailFormat(email) {
      const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      return emailFormat.test(email);
    },
    validatePassword() {
      if (this.password.length === 0) {
        this.passwordState = false;
        this.isValidPassword = true;
      } else if (this.password.length < 6) {
        this.passwordState = false;
        this.isValidPassword = false;
      } else {
        this.passwordState = true;
        this.isValidPassword = true;
      }
    },
    togglePasswordVisibility() {
      this.passwordReveal = !this.passwordReveal;
    },
  },
};
</script>

<style scoped>
.login-page {
  /* margin-top: 50px; */
  background-color: #f9f9f9;
  display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.login-card {
  border-radius: 0.475rem !important;
  border: none !important;
  outline: none !important;
  margin: 10px;
  margin-bottom: 0px;
  padding: 0.5rem !important;
  background-color: #FFFFFF;
  width: 80%;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */

}

.login-form {
  max-width: 350px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.login-form .form-input:focus {
  /* border-color: #007bff; */
  /* box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25); */
}

.form-group {
  margin-bottom: 20px;
}

.form-input:focus {
  border-color: #2274BD;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
}

.login-button , .login-button:hover, .btn-dark:focus , .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  /* font-weight: bold; */
  /* background-color: #007bff;
  border-color: #007bff; */
  background-color: #2274BD;
  color: #fff;
  font-family: 'Somar-Bold';
  font-size: 20px;
  border-color: #2274BD !important;
}

.login-button:hover {
  /* background-color: #0056b3;
  border-color: #0056b3; */
}

.btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #2274BD !important;
    border-color: #2274BD !important;
}

.error-message {
  color: #ff5252 !important;
  margin-top: 5px;
  font-size: 17px;
  font-family: 'Somar-Regular';
}

.toggle-password {
  position: absolute;
  top: 9px;
  z-index: 1000;
  cursor: pointer;
  font-size: 17px;
}

.toggle-rtl {
  left: 9px;
}

.toggle-ltr {
  right: 9px;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none !important;
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: transparent !important;
  border-bottom: none !important;
}

.card-body {
  padding: 10px;
}

.background-image {
  background-size: cover;
  background-position: center;
  background-image: url('@/assets/bg.webp');
  height: 100vh !important
}
.login-title{
  color: #2274BD !important;
  font-size: 2.5rem !important;
  font-family: 'Somar-Bold';
}
.required-label{
  color: #2274BD !important;
  font-size: 1.2rem !important;
  font-family: 'Somar-Regular';
}
.login-parag{
  color: #A1A5B7;
  font-size: 1.2rem !important;
  font-family: 'Somar-Medium';
  margin-bottom: 0.5rem;
}
.col-form-label{
  margin-bottom: 0.5rem !important;
  font-size: 1.05rem !important;
  font-weight: 500 !important;
  color: #3F4254 !important;
} 
.col-form-label:after {
    content: "*";
    position: relative;
    font-size: inherit;
    color: var(--bs-danger);
    padding-right: 0.25rem;
    font-weight: 600;
}
.required-star {
    color: red;
}
.forget-password{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem !important;
}
.forget-password a{
  transition: color 0.2s ease;
  text-decoration: none;
  color: #000 !important;
  font-size: 1rem !important;
  font-weight: 500;
}
.forget-password a:hover{
  color: #00f !important;
}
.login-footer{
  margin-top: 10px;
  font-size: 18px;
  font-family: 'Somar-Medium';
  
}
.text-gray-400{
    color:#B5B5C3;
}
.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width)* 2));
  padding: .366rem .75rem;
  font-size: 0.875rem;
  border-radius: 0.425rem;
}
.form-control:focus {
    color: #5E6278;
    border-color: #B5B5C3;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(#000, 0.25) !important;
}
.form-control.is-invalid:focus {
    border-color: #ff5252 !important;
    box-shadow: none !important;
}
.form-control.is-invalid{
    padding-right: 0.75rem !important;
}
.form-control{
  color: #5E6278;
  font-size: 18px;
  background-color: transparent;
}
.form-control.is-valid {
  background-image: none !important;
}

/* .b-icon.bi {
    margin-right: 23px;
    margin-left: 23px;
    margin-top: 2px;
} */

@media (min-width: 1200px) {
    .container-xl, .container-lg, .container-md, .container-sm, .container {
        max-width: 100%;
        height: 100vh;
        overflow: hidden
    }
}

.toast-rtl {
  direction: rtl;
  text-align: right; /* محاذاة النص لليمين في اللغة العربية */
}

.toast-ltr {
  direction: ltr;
  text-align: left; /* محاذاة النص لليسار في اللغة الإنجليزية */
}
</style>
