<template>
  <div>
    <div class="slide">
            <div class="bg-img">
                <div class="slide-content">
                    <div><h2 class="slide-header">About us</h2></div>
                <div>
                    <h5 class="slide-h5">Home</h5>
                    <b-icon :icon="isRTL ? 'chevron-double-left' : 'chevron-double-right'" class="icon-slider"></b-icon>
                    <h5 class="slide-h5"> About us</h5>
                </div>
            </div>
            </div>
        </div>
    <!-- Header Section -->
    <div class=" mb-5">
      <b-container>
        <b-row>
          <b-col>
            <h4 class="text-center my-4">
              Revolutionize Your
              Maintenance Work
              flow with EverOn
            </h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <p class="text-center about-paragraph">
              Streamlining Maintenance Processes
              for Efficiency and Productivity
            </p>
          </b-col>
        </b-row>
      </b-container>

      <!-- Introduction Section -->
      <div class="introduction-section mb-5">
        <b-container>
          <h4 class="text-center mt-5 my-4">Introduction</h4>
          <b-row>
            <b-col cols="12" sm="6" lg="5">
              <b-card class="mb-4" :img-src="require('../assets/Introduction1.jpg')" img-alt="Image 1" img-top>
                <b-card-text>
                  <h6>Current Challenges in Maintenance
                    Processes</h6>
                  <p class="introduction-paragraph">Maintenance processes can be complex
                    and time-consuming, often leading to
                    inefficiencies, delays, and increased costs</p>

                </b-card-text>
              </b-card>
            </b-col>
            <b-col cols="12" sm="6" lg="5">
              <b-card class="mb-4" :img-src="require('../assets/Introduction2.jpg')" img-alt="Image 1" img-top>

                <b-card-text>
                  <h6>Importance of an Efficient Maintenance
                    Service Application</h6>
                  <p class="introduction-paragraph">An efficient maintenance service application like
                    EverOn can streamline and automate maintenance
                    processes, improving productivity, reducing
                    downtime, and enhancing overall efficiency.</p>
                </b-card-text>
              </b-card>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>

    <div class="problems-section mb-5">
      <!-- Introduction Section -->
      <b-container>
        <!-- <h4 class="text-center mt-5 my-4">Introduction</h4> -->
        <b-row>
          <b-col cols="12" sm="6" lg="5">
            <div class="problem-section">
              <h4 class="problem">Problem Statement</h4>
              <ul class="list-unstyled">
                <li>
                  <h6><span style="display: block;font-size: 28px;">Downtime</span>
                    Manual processes / lack of automation resulting in
                    lost productivity and revenue.</h6>
                </li>
                <li>
                  <h6><span style="display: block;font-size: 28px;">Costs</span>

                    Higher costs due to unplanned repairs, excessive
                    inventory, and wasted resources.</h6>
                </li>
                <li>
                  <h6><span style="display: block;font-size: 28px;">Overall Efficiency</span>


                    Maintenance tasks can take longer to complete,
                    leading to delays and reduced efficiency</h6>
                </li>
              </ul>
            </div>
          </b-col>
          <b-col cols="12" sm="6" lg="5">
            <div class="solution-section">
              <h4 class="solution"> Solution</h4>
              <ul class="list-unstyled">
                <li>
                  <h6>Track tasks, schedule work orders, and communicate
                    with teams easily</h6>
                </li>
                <li>
                  <h6>Centralized platform ensures timely maintenance and
                    reduces miscommunication.</h6>
                </li>
                <li>
                  <h6>Automation and real-time updates optimize maintenace
                    processes for increased efficiency.</h6>
                </li>
                <li>
                  <h6>Monitor KPIs (Availability, MTTR, MTBF,…. etc.)
                  </h6>
                </li>
              </ul>
            </div>
          </b-col>
          <b-col cols="12" sm="12" lg="12">
            <div class="challenges">
              <h1> These challenges highlight the need for
                EverOn </h1>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <div class="case-study-section mb-5">
      <b-container>
        <h4 class="text-center mt-5 my-4">Case Study</h4>
        <b-row>
          <b-col cols="12" sm="6" lg="5">
            <div class="case-study">
              <ul style=" padding-left: 5px; padding-right: 5px;">
                <h6><span style="display: block;font-size: 28px;">Challenges Faced</span></h6>
                <li>
                  <h6>High maintenance costs: $15,000 annually, emergency repairs $5,000/year.</h6>
                </li>
                <li>
                  <h6>Downtime: 8 hours per breakdown, $3,000 monthly production loss.</h6>
                </li>
                <li>
                  <h6>Availability: Steam boiler availability 85%.</h6>
                </li>
                <h6><span style="display: block;font-size: 28px;">Solution & Results</span></h6>
                <li>
                  <h6>Maintenance Management App implementation.</h6>
                </li>
                <li>
                  <h6>Cost reduction: 30% savings ($10,500 annually),
                    emergency repairs down 50% ($2,500/year).</h6>
                </li>
                <li>
                  <h6>Downtime reduction: 50% decrease
                    (4 hours per breakdown),
                    production loss down 40% ($1,800 monthly).</h6>
                </li>
                <li>
                  <h6>Availability boost: Steam boiler availability up to 95%.</h6>
                </li>
              </ul>
            </div>
          </b-col>

          <b-col cols="12" sm="6" lg="5" class="case-study-img">
            <div>
              <img :src="require('../assets/Case-Study.jpg')" style="width: 100%;height: 220px;" img-alt="Image 1" img-top />
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>


    <div class="key-features-section mb-5">
      <b-container>
        <h4 class="text-center mt-5 my-4">Key Features</h4>
        <b-row>
          <b-col cols="12" sm="6" lg="5">
            <div class="key-features">
              <h6><span style="font-size: 28px;">EverOn</span>
                is a comprehensive maintenance service application that
                offers a Full control on the remote maintenance operations, asset
                management, and reporting and analytics.</h6>
            </div>
          </b-col>

          <b-col cols="12" sm="6" lg="5" class="key-features-img">
            <div>
              <img :src="require('../assets/Key-Features.jpg')" style="height: 130px;" img-alt="Image 1"
                img-top />
            </div>
          </b-col>
          <b-col cols="12" lg="10" sm="12">
            <h4 class="text-center mt-5 my-4">Main Features</h4>
            <b-table striped bordered hover :items="features" :fields="fields" class="text-center custom-header">
              <template #cell(Feature)="data">
                <strong>{{ data.item.Feature }}</strong>
              </template>
            </b-table>

          </b-col>
        </b-row>
      </b-container>
    </div>


    <div class="user-friendly-interface-section mb-5">
      <b-container>
        <h4 class="text-center mt-5 my-4">Intuitive and user-friendly Interface</h4>
        <p class="text-center about-paragraph">
          With a clean and organized interface, users can easily access the app's features
          and functionalities, navigate and perform tasks.
        </p>
        <b-row>
          <b-col cols="3">
            <img :src="require('../assets/5.jpg')" style="width: 100%;" img-alt="Image 1" img-top />
          </b-col>
          <b-col cols="3">
            <img :src="require('../assets/6.jpg')" style="width: 100%;" img-alt="Image 1" img-top />
          </b-col>
          <b-col cols="3">
            <img :src="require('../assets/7.jpg')" style="width: 100%;" img-alt="Image 1" img-top />
          </b-col>
          <b-col cols="3">
            <img :src="require('../assets/8.jpg')" style="width: 100%;" img-alt="Image 1" img-top />
          </b-col>
        </b-row>

      </b-container>
    </div>



    <div class="implementation-section mb-5">
      <b-container>
        <h4 class="text-center mt-5 my-4">Implementation and Support</h4>
        <b-row>
          <b-col cols="12" sm="6" lg="5">
            <div class="easy-implementation">
              <div class="easy-implementation-img">
                <img :src="require('../assets/Implementation1.png')" style="width: 80%;height: 240px;" img-alt="Image 1" img-top />
              </div>
              <h3 class="easy-paragraph">Easy Implementation</h3>
              <ul class="list-unstyled" style="">
                <li>
                  <h6> <span style="font-size: 28px;">EverOn</span> integrates into existing workflows, making
                    the transition smooth and efficient.</h6>
                </li>
                <li>
                  <h6>Implementation process is straightforward and can
                    be completed with minimal disruption to daily
                    operations</h6>
                </li>
              </ul>
            </div>
          </b-col>

          <b-col cols="12" sm="6" lg="5">
            <div class="user-support">
              <div class="easy-implementation-img">
                <img :src="require('../assets/Implementation2.png')" style="width: 80%;height: 240px;" img-alt="Image 1" img-top />
              </div>
              <h3 class="easy-paragraph">User Support and Training</h3>
              <ul class="list-unstyled" style="">
                <li>
                  <h6>Comprehensive support and training options
                    to ensure users can make the most of our application.</h6>
                </li>
                <li>
                  <h6>Available to assist users with any questions or
                    issues they may encounter.
                  </h6>
                </li>
              </ul>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <!-- Conclusion Section -->
    <div class="Conclusion-section mb-5">
        <b-container>
          <h4 class="text-center mt-5 my-4">Conclusion</h4>
          <b-row>
            <b-col cols="12" sm="6" lg="5">
              <b-card class="mb-4" :img-src="require('../assets/Conclusion1.jpg')" img-alt="Image 1" img-top>

                <b-card-text>
                  <h6>Streamlined Maintenance Processes</h6>
                  <p class="introduction-paragraph">The application simplifies and automates maintenance tasks, 
                    saving time and reducing errors</p>
                  <p class="introduction-paragraph">Centralized communication and task management improve 
                    efficiency and coordination among team members.</p>

                </b-card-text>
              </b-card>
            </b-col>
            <b-col cols="12" sm="6" lg="5">
              <b-card class="mb-4" :img-src="require('../assets/Conclusion2.jpg')" img-alt="Image 2" img-top>

                <b-card-text>
                  <h6>Real-time Data Insights</h6>
                  <p class="introduction-paragraph">Access to real-time maintenance data and analytics enables 
                    proactive decision-making and preventive maintenance.</p>
                  <p class="introduction-paragraph">Identify trends, patterns, and areas for improvement to 
                    optimize maintenance processes and resource allocation.</p>
                </b-card-text>
              </b-card>
            </b-col>
          </b-row>
        </b-container>
      </div>

  </div>
</template>

<script>
// import slider from '../components/slider.vue'
export default {
  name: "about",
  components: {
    // slider,
  },
  data() {
    return {
      fields: [
        { key: 'Feature', label: 'Feature' },
        { key: 'Description', label: 'Description' },
      ],
      features: [
        { Feature: 'Work Order Management', Description: 'Efficiently create, assign, and track work orders, ensuring timely completion of maintenance tasks.' },
        { Feature: 'Asset Management', Description: 'Keep track of all your assets, including equipment, machinery, and facilities, with detailed information and maintenance history.' },
        { Feature: 'Mobile Accessibility', Description: 'Access and manage your maintenance tasks anytime, anywhere using the EverOn mobile app.' },
        { Feature: 'Reporting and Analytics', Description: 'Generate comprehensive reports and gain valuable insights into maintenance performance, enabling data-driven decision-making.' },
      ],
    };
  },
}

</script>

<style scoped>
p {
  font-family: 'Somar-Bold';
  color: #7F98BB;
  font-size: 18px;
  font-weight: 100;
  line-height: 1.625em;
}

.row {
  justify-content: center;
}

.card {
  height: 450px !important;
  min-height: 450px;
  max-height: 450px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

h4 {
  color: #2274BD;
  font-family: 'Somar-Bold';
  font-size: 28px;
}

h6 {
  color: #2274BD;
  font-family: 'Somar-Regular';
  font-size: 24px;
}

h6 span {
  font-family: 'Somar-Bold';
}

.card-text h6 {
  margin-bottom: 15px;
}

.introduction-paragraph {
  line-height: 1.2em;
}

.problem,
.solution {
  border: 1px solid #2274BD;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
}

.problem {
  background-color: rgb(247 177 177);
}

.solution {
  background-color: #9eff9e;
}

.problem-section li {
  /* height: 80px; */
  width: 80%;
}

.solution-section li {
  height: 80px;
}

.solution-section ul,
.problem-section ul {
  margin-top: 30px
}

.challenges {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #d1e4ff;
  border: 1px solid #2274BD;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  margin-left: 180px;
  margin-right: 180px;
  color: #2274BD;
}

.case-study-section,
.user-friendly-interface-section,
.implementation-section,
.problems-section {
  margin-top: 100px;
}

.introduction-section {
  margin-top: 50px;
}

.case-study-img {
  /* margin-top: 40px; */
  /* display: flex; */
  /* align-items: center */
  margin-top: 50px;
}

.case-study ul h6 span {
  margin-top: 30px;
}

.key-features-section {
  margin-top: 100px;
}


.key-features-img {
  /* margin-top: 40px; */
  /* display: flex; */
  /* align-items: center */
  /* margin-top: 50px; */
}

.table {
  background-color: white;
  margin-bottom: 0;
}

.table th {
  background-color: #2274BD !important;
  color: #2274BD;
  text-transform: uppercase;
}

.table td {
  vertical-align: middle;
}

.easy-implementation-img {
  display: flex;
  justify-content: center;
  align-items: center
}

.easy-paragraph {
  text-align: center;
  font-family: 'Somar-Bold';
  /* margin-top: 30px; */
  color: #2274BD;
}

.user-support ul li,
.easy-implementation ul li {
  /* width: 400px */
}

.list-unstyled {
  padding-right: 0px;
}

.card-img-top {
  width: 100%;height: 220px;
}

@media (max-width: 576px) {
  .row {
    justify-content: unset;
  }

  .challenges {
    margin-left: 0px;
    margin-right: 0px;
  }
}
</style>
