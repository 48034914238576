<template>
  <div v-if="isLoading" class="loading-container">
        <img :src="require('@/assets/10.png')" alt="Logo" class="loading-logo" />
  </div>
  <div v-else class="home">
    <!-- <h1>{{ $t('message.homePageTitle') }}</h1> -->
    
    <!-- <card :cardTitle="cardTitle" :cardPara="cardPara" /> -->
    <HeroSection />
    <OrdersSection />
    <ourRequests />
    <OurEmployees />
    <!-- {{ 'name =' + user.name }}
    {{'id =' + user.id}} -->
    <LocationMap />  

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
// import paginationTable from '../components/table.vue'
// import card from '../components/card.vue'
import HeroSection from "@/components/HeroSection.vue";
import OrdersSection from "@/components/OrdersSection.vue";
import ourRequests from "@/components/ourRequests.vue";
import OurEmployees from "@/components/OurEmployees.vue";
import LocationMap from '@/components/LocationMap.vue';  


export default {
  name: 'HomeView',
  components: {
    // paginationTable,
    // card
    HeroSection,
    OrdersSection,
    ourRequests,
    OurEmployees,
    LocationMap,  

  },
  data(){
    return{
      cardTitle: '',
      cardPara:'',
      currentLanguage: this.$i18n.locale,
      isLoading: true,
    }
  },
  computed: {
    ...mapGetters(['getUser']),
    user() {
      return this.getUser; // استخدام الـ getter لجلب بيانات المستخدم
    }
  },
  created(){
    this.cardTitle = 'test Card Title';
    this.cardPara = "test Some quick example text to build on the card title and make up the bulk of the card's content."
    setTimeout(() => {
      this.isLoading = false;
    }, 2000);
  },
  methods: {
    switchLanguage(language) {
      this.currentLanguage = language;
      this.$i18n.locale = language;
    },
  }
}
</script>

<style scoped>

.bg-img {
  height: 205px;
  background-image: url(../assets/slide-photo.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex; /* استخدم Flexbox */
  justify-content: center; /* محاذاة أفقيًا في المنتصف */
  align-items: center; /* محاذاة رأسيًا في المنتصف */
  text-align: center; /* محاذاة النصوص في المنتصف */
}
.slide-content{
  color: #2274BD;
}
.slide-content .slide-header{
  text-transform: uppercase;
}
</style>
