<template>
    <div class="text-center">
        <h1> {{ $t('message.NotFoundMessage') }} </h1>
        <p> </p>
    </div>
</template>
<script>
export default{
    name: "NotFound"
}
</script>

<style scoped>
</style>