import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import HomeView from '../views/HomeView.vue'
import CompaniesView from '../views/CompaniesView.vue'
import AboutView from '../views/AboutView.vue'
import OrdersView from '../views/OrdersView.vue'
import NotFound from '../views/NotFound.vue'
import LoginView from '../views/LoginView.vue'
import ShowDetails from '../views/ShowDetails.vue'
import addNewOrder from '../views/addNewOrder.vue'
import RequestsView from '../views/RequestsView.vue'
import EmployeesView from '../views/EmployeesView.vue'
import profile from '../views/profile.vue'
import locations from '../views/locations.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { requiresAuth: true } // يحتاج المستخدم إلى تسجيل الدخول
  },
  // {
  //   path: '/',
  //   redirect: '/About-us'  // تحويل المسار الجذري إلى صفحة "About-us"
  // },
  {
    path: '/Companies',
    name: 'Companies',
    component: CompaniesView,
    meta: { requiresAuth: true } // يحتاج المستخدم إلى تسجيل الدخول

  },
  {
    path: '/about-us',
    name: 'About',
    component: AboutView,
    meta: { requiresAuth: false } // يحتاج المستخدم إلى تسجيل الدخول

  },
  {
    path: '/orders',
    name: 'orders',
    component: OrdersView,
    meta: { requiresAuth: true } // يحتاج المستخدم إلى تسجيل الدخول

  },
  {
    path: '/orders/:id/details',
    name: 'ShowDetails',
    component: ShowDetails,
    meta: { requiresAuth: true },
    props: true
  },  
  {
    path: '/addNewOrder/:id?',
    name: 'addNewOrder',
    component: addNewOrder,
    meta: { requiresAuth: true },
    props: true
  },  
  {
    path: '/Requests',
    name: 'RequestsView',
    component: RequestsView,
    meta: { requiresAuth: true },
  },  
  {
    path: '/Employees',
    name: 'EmployeesView',
    component: EmployeesView,
    meta: { requiresAuth: true },
  },  
  {
    path: '/profile',
    name: 'profile',
    component: profile,
    meta: { requiresAuth: true },
  },  
  {
    path: '/locations',
    name: 'locations',
    component: locations,
    meta: { requiresAuth: true },
  },  
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: { requiresAuth: false } // لا يحتاج المستخدم إلى تسجيل الدخول
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
})

export function createRouter(i18n) {
  router.beforeEach((to, from, next) => {
    // إزالة '/' من نهاية المسار إذا كان موجودًا
    if (to.path !== '/' && to.path.endsWith('/')) {
      next({ path: to.path.slice(0, -1), query: to.query });
      return;
    }

    const isAuthenticated = !!localStorage.getItem('userId'); // تحقق من وجود userId في localStorage

    console.log('Navigating to:', to.path);
    console.log('User authenticated:', store.getters.isAuthenticated);

    // if (store.getters.isAuthenticated === false) {
    //   // إظهار رسالة تنبيه إذا لم يكن مسجلًا
    //   // this.$bvToast.toast('Please log in to access this page', {
    //   //   title: 'Login Required',
    //   //   variant: 'danger',
    //   //   solid: true,
    //   //   toaster: 'b-toaster-bottom-left',
    //   // });
    //   // setTimeout(() => {
    //   //   // توجيه المستخدم إلى صفحة تسجيل الدخول بعد ظهور الرسالة
    //   //   this.$router.push('/login');
    //   // }, 2000);
    //   console.log('login-frist');
    //   alert('login first')      
    // }
  
    if (to.meta.requiresAuth && !isAuthenticated) {
      next('/about-us');
    } else if (!to.meta.requiresAuth && isAuthenticated && to.path === '/login') {
      next('/');
    } else {
      next();
    }
   
  });
  return router;
}

