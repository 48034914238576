<template>
    <div>
        <div class="slide mb-5">
            <div class="bg-img">
                <div class="slide-content">
                    <div><h2 class="slide-header"> {{$t('message.profile')}}</h2></div>
                    <div>
                        <h5 class="slide-h5">{{$t('message.home')}}</h5>
                        <b-icon :icon="isRTL ? 'chevron-double-left' : 'chevron-double-right'" class="icon-slider"></b-icon>
                        <h5 class="slide-h5"> {{$t('message.profile')}}</h5>
                    </div>
                </div>
            </div>
        </div>


        <b-card no-body class="mb-4" style="max-width: 400px; margin: auto;">
            <b-card-header class="text-center">
                <div class="modal-headerr d-flex align-items-center justify-content-center">
                <div class="modal-image">
                    <img @click="browseImage" :src="user.image || require('../assets/victor.png')" alt="Employee Image" class="rounded-circle" style="width: 80px; height: 80px; cursor: pointer;" />
                    <h4>{{ user.name }}</h4>
                    <p>{{ user.email }}</p>
                </div>
                </div>
            </b-card-header>

            <b-card-body class="px-4">
                <div class="form-group">
                <label for="name"> <b-icon icon="person" aria-hidden="true"></b-icon> {{$t('message.userName')}}</label>
                <input type="text" class="form-control" v-model="user.name">
                </div>

                <div class="form-group">
                <label for="exampleInputEmail1"> <b-icon icon="voicemail" aria-hidden="true"></b-icon> {{$t('message.emailAddress')}}</label>
                <input type="email" class="form-control" v-model="user.email">
                </div>

                <div class="form-group">
                <label for="Phone Number"> <b-icon icon="telephone" aria-hidden="true"></b-icon> {{$t('message.phoneNumber')}}</label>
                <input type="number" class="form-control" v-model="user.phone">
                </div>

                <div class="form-group">
                <label for="Password"> <b-icon icon="lock" aria-hidden="true"></b-icon> {{$t('message.password')}}</label>
                <input :type="passwordReveal ? 'text' : 'password'" class="form-control" v-model="user.password">
                <b-icon class="toggle-password" :class="[{ 'toggle-rtl': isRTL, 'toggle-ltr': !isRTL }]"
                      @click="togglePasswordVisibility" :icon="passwordReveal ? 'eye-slash' : 'eye'"></b-icon>
                </div>
                <input type="file" ref="imageInput" @change="onImageSelected" style="display: none;" />
            </b-card-body>

            <b-card-footer class="text-right">
                <b-button  size="md" @click="showModal = false">{{ $t('message.update') }}</b-button>
            </b-card-footer>
        </b-card>

        
    </div>
</template>

<script>
import axiosInstance from '../axios-config';
import { mapGetters } from 'vuex'

export default {
    name: "profile",
    data() {
        return {
            passwordReveal: false,
        };

    },
    computed: {
        ...mapGetters(['getUser']),
        company_id() {
            return this.getUser; // استخدام الـ getter لجلب بيانات المستخدم
        },
        user() {
            return this.getUser; // استخدام الـ getter لجلب بيانات المستخدم
        },
        isRTL() {
            return this.$i18n.locale === 'ar';
        },
    },
    created() {
        // this.fetchData();
    },
    methods: {

        togglePasswordVisibility() {
        this.passwordReveal = !this.passwordReveal;
        },

        // async fetchData() {
        //     try {
        //         const formData = new FormData();
        //         // formData.append('current_date', '2023-12-14');
        //         formData.append('offset', 0);
        //         formData.append('company_id', this.company_id.id);
        //         const response = await axiosInstance.post('company/select_orders.php', formData);

        //         console.log(response.data);
        //         if (response.data.status === "true") {
        //             this.orders = response.data.output;
        //             console.log('orders = ', this.orders);

        //         }
        //     } catch (error) {
        //         console.error('Error logging in:', error)
        //         this.$bvToast.toast('An error occurred while trying to get data', {
        //             title: 'Error',
        //             variant: 'danger',
        //             solid: true,
        //             toaster: 'b-toaster-bottom-left', // Position of the toast
        //             // autoHideDelay: 1000,
        //         });
        //     }
        // },

        // فتح نافذة اختيار الصورة
        browseImage() {
            this.$refs.imageInput.click();
        },
        // التعامل مع الصورة المختارة
        onImageSelected(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.user.image = e.target.result; // تعيين الصورة المختارة
                };
                reader.readAsDataURL(file);
            }
        },
    }
}

</script>

<style scoped>
.row {
    justify-content: center;
}
.card{
    border-radius: 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 20px;
}
.card-header {

}
.form-control {
  background: transparent;
  border-width: 0px;
  border-bottom: 2px solid #D1E4FF;
  color: #7F98BB;
  font-family: 'Somar-Medium';
  font-size: 20px;

}
.b-icon.bi {
  color: #FFD95F;
  margin-right: 2px;
  font-size: medium;
}

.form-control:focus {
  box-shadow: none !important;
}

label {
  color: #45566D;
  font-family: 'Somar-Bold';
  font-size: 20px;
  margin-left: -10px;
}
.card-footer{
    background-color: transparent;
    border-top: 0px transparent;
}
.card-footer button{
    width: -webkit-fill-available;
    border-radius: 20px;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    background-image: linear-gradient(90deg, #FFCA1F 0%, #FFD44A 100%);
    background-color: transparent !important;
    border-color: transparent !important;
    font-size: 18px;
    font-family: 'Somar-Bold';
}
.card-footer button:hover{
    background-color: transparent !important;
    border-color: transparent !important;
    background-image: linear-gradient(90deg, #FFD44A 0%,#FFCA1F 100%);
}
.toggle-password {
  position: absolute;
  bottom: 123px;
  z-index: 1000;
  cursor: pointer;
  font-size: 18px !important;
  color: #000 !important;
}
.toggle-rtl {
  left: 44px;
}

.toggle-ltr {
  right: 40px;
}
.modal-image h4{
    font-size: 25px;
    font-family: 'Somar-Bold';
    margin-top: 5px;
    margin-bottom: 0px;
    color: #2274BD;
}
.modal-image p{
    margin-bottom: 0px;
    font-size: 20px;
    font-family: 'Somar-Medium';
    color: #45566D;
}
</style>