<template>
    <div v-if="isLoading" class="loading-container">
        <img :src="require('@/assets/10.png')" alt="Logo" class="loading-logo" />
    </div>
    <div v-else>
        <div class="slide">
            <div class="bg-img">
                <div class="slide-content">
                    <div><h2 class="slide-header"> {{$t('message.ourOrders')}}</h2></div>
                <div>
                    <h5 class="slide-h5">{{$t('message.home')}}</h5>
                    <b-icon :icon="isRTL ? 'chevron-double-left' : 'chevron-double-right'" class="icon-slider"></b-icon>
                    <h5 class="slide-h5"> {{$t('message.orders')}}</h5>
                </div>
            </div>
            </div>
        </div>
        <div v-if="Object.keys(this.order).length" class="container custom-container-orders mt-5">
            <b-button variant="link" class="back-btn" @click="$router.go(-1)"> <b-icon
                    :icon="isRTL ? 'chevron-right' : 'chevron-left'" class="icon-slider"></b-icon>{{ $t('message.back') }}</b-button>
            <b-tabs pills card>
                <b-tab :title="$t('message.orderDetails')" active>
                    <div>
                        <!-- Order Details content here -->
                        <b-row>
                            
                                <b-col cols="12">
                                <div class="equipments-header">
                                    <p><strong>{{$t('message.orderNumber')}}</strong></p>
                                    <p> {{this.order.id}}</p>
                                </div>
                                <div class="equipments-header">
                                    <p><strong>{{$t('message.orderTime')}}</strong></p>
                                    <p>{{this.order.created_date}}</p>
                                </div>
                                <div class="equipments-header">
                                    <p><strong>{{$t('message.companyName')}}</strong></p>
                                    <p>{{this.order.customer_name}}</p>
                                </div>
                                <div class="equipments-header">
                                    <p><strong>{{$t('message.adminOfTheOrder')}}</strong></p>
                                    <p> {{this.order.adminName}}</p>
                                </div>
                                <div class="equipments-header">
                                    <p><strong>{{$t('message.clientName')}}</strong></p>
                                    <p> {{this.order.customer_name}}</p>
                                </div>
                                <div class="equipments-header">
                                    <p><strong>{{$t('message.phoneNumber')}}</strong></p>
                                    <p> {{this.order.customer_phone}}</p>
                                </div>
                                <div class="equipments-header">
                                    <p><strong>{{$t('message.address')}}</strong></p>
                                    <p style="margin-left: 45px;"><a :href="googleMapsLink(this.order.customerMapUrl)" target="_blank">{{ this.order.customerMapUrl }}</a></p>
                                </div>
                                <div class="equipments-header">
                                    <p><strong>{{$t('message.services')}}</strong></p>
                                    <p> {{this.order.serviceName}}</p>
                                </div>
                                <div class="equipments-header">
                                    <p><strong>{{$t('message.Technician')}}</strong></p>
                                    <p> {{this.order.technicalLeaderName}}</p>
                                </div>
                            </b-col>
                            
                            
                        </b-row>
                    </div>
                    <hr style="background-color: #C0DBFF;height: 2px;">
                    <div>
                        <b-row>
                            <b-col cols="12">
                                <div class="equipments-header">
                                    <p><strong>{{$t('message.status')}}</strong></p>
                                    <p>{{statusName }}</p>
                                </div>
                            </b-col>
                            
                        </b-row>
                    </div>
                </b-tab>

                <b-tab :title="$t('message.equipments')">
                    <div class="mb-5">
                        <b-row>
                            <b-col cols="12">
                                <div class="equipments-header">
                                    <p><strong>{{ $t('message.equipmentRepalced') }}</strong></p>
                                    <p> {{equipmentReplacedText }}</p>
                                </div>
                                <div class="equipments-header">
                                    <p><strong>{{ $t('message.equipmentRepalcedAvailable') }}</strong></p>
                                    <p>{{equipmentReplacedText}}</p>
                                </div>
                                <div class="equipments-header">
                                    <p><strong>{{ $t('message.errorType') }}</strong></p>
                                    <p>{{this.order.error_type}}</p>
                                </div>
                                <div class="equipments-header">
                                    <p><strong>{{ $t('message.activityDomains') }}</strong></p>
                                    <p> {{this.order.activity_domain}}</p>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="mb-5">
                        <b-table
                        :items="items"
                        :fields="fields"
                        responsive="sm"
                        hover 
                        striped 
                        bordered
                        >

                        <template #thead-top="data">
                            <b-tr>
                            <b-th style="width: 20%;"colspan="1"><span class="sr-only">Status</span></b-th>
                            <b-th class="repalced-equipment" style="width: 40%;">Repalced Equipment</b-th>
                            <b-th class="spare-parts"style="width: 40%;" colspan="3">Spare Parts</b-th>
                            <!-- <b-th variant="danger">Type 3</b-th> -->
                            </b-tr>
                        </template>
                        </b-table>
                    </div>
                    <div class="mb-5">
                        <b-row>
                            <b-col cols="12">
                                <div class="equipments-header">
                                    <p><strong>{{$t('message.subEquipmentReplaced')}}</strong></p>
                                    <p> {{this.order.equipment_replaced}}</p>
                                </div>
                                <div class="equipments-header">
                                    <p><strong>{{ $t('message.subEquipmentReplacedAvailable') }}</strong></p>
                                    <p>{{this.order.sub_equipment_replaced}}</p>
                                </div>
                                <div class="equipments-header">
                                    <p><strong>{{$t('message.errorType')}}</strong></p>
                                    <p>{{this.order.error_type}}</p>
                                </div>
                                <div class="equipments-header">
                                    <p><strong>{{$t('message.activityDomains')}}</strong></p>
                                    <p> {{this.order.activity_domain}}</p>
                                </div>
                            </b-col>
                        </b-row>
                    </div>


                    <div class="mb-5">
                        <b-table
                        :items="items"
                        :fields="fields"
                        responsive="sm"
                        hover 
                        striped 
                        bordered
                        >

                        <template #thead-top="data">
                            <b-tr>
                            <b-th style="width: 20%;"colspan="1"><span class="sr-only">Status</span></b-th>
                            <b-th class="repalced-equipment" style="width: 40%;">Replaced Sub Equipment</b-th>
                            <b-th class="spare-parts"style="width: 40%;" colspan="3">Spare Parts</b-th>
                            <!-- <b-th variant="danger">Type 3</b-th> -->
                            </b-tr>
                        </template>
                        </b-table>
                    </div>
                </b-tab>

                <b-tab title="The Time">
                    <div>
                        <div class="">
                            <b-row>
                                <b-col cols="12">
                                    <div class="equipments-header">
                                        <p><strong>{{$t('message.siteArrivalDate')}}</strong></p>
                                        <p> {{this.order.site_arrival_date}}</p>
                                    </div>
                                    <div class="equipments-header">
                                        <p><strong>{{$t('message.siteArrivalTime')}}</strong></p>
                                        <p>{{this.order.site_arrival_time}}</p>
                                    </div>
                                    <div class="equipments-header">
                                        <p><strong>{{$t('message.repairStartDate')}}</strong></p>
                                        <p>{{this.order.repair_start_date}}</p>
                                    </div>
                                    <div class="equipments-header">
                                        <p><strong>{{$t('message.repairEndDate')}}</strong></p>
                                        <p> {{this.order.repair_end_date}}</p>
                                    </div>
                                    <div class="equipments-header">
                                        <p><strong>{{$t('message.repairEndTime')}}</strong></p>
                                        <p> {{this.order.repair_end_time}}</p>
                                    </div>
                                    <div class="equipments-header">
                                        <p><strong>{{$t('message.startLocationLatitude')}}</strong></p>
                                        <p> {{this.order.start_latitude}}</p>
                                    </div>
                                    <div class="equipments-header">
                                        <p><strong>{{$t('message.startLocationLongitude')}}</strong></p>
                                        <p> {{this.order.start_longitude}}</p>
                                    </div>
                                    <div class="equipments-header">
                                        <p><strong>{{$t('message.endLocationLatitude')}}</strong></p>
                                        <p> {{this.order.end_latitude}}</p>
                                    </div>
                                    <div class="equipments-header">
                                        <p><strong>{{$t('message.endLocationLongitude')}}</strong></p>
                                        <p> {{this.order.end_longitude}}</p>
                                    </div>
                                    <div class="equipments-header">
                                        <p><strong>{{$t('message.siteDepartureDate')}}</strong></p>
                                        <p> {{this.order.site_departure_date}}</p>
                                    </div>
                                    <div class="equipments-header">
                                        <p><strong>{{$t('message.siteDepartureTime')}}</strong></p>
                                        <p> {{this.order.site_departure_time}}</p>
                                    </div>
                                    <div class="equipments-header">
                                        <p><strong>{{ $t('message.ttf') }}</strong></p>
                                        <p> {{this.order.ttf}}</p>
                                    </div>
                                    <div class="equipments-header">
                                        <p><strong>{{ $t('message.tta') }}</strong></p>
                                        <p> {{this.order.tta}}</p>
                                    </div>
                                    <div class="equipments-header">
                                        <p><strong>{{ $t('message.ttr') }}</strong></p>
                                        <p> {{this.order.ttr}}</p>
                                    </div>
                                    <div class="equipments-header">
                                        <p><strong>{{ $t('message.tbf') }}</strong></p>
                                        <p> {{this.order.tbf}}</p>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>

                    </div>
                </b-tab>

                <b-tab title="The Cost">
                    <div>
                        <div>
                        <b-row>
                            <b-col cols="12">
                                <div class="equipments-header">
                                    <p><strong>{{$t('message.replacedSparePartCost')}}</strong></p>
                                    <!-- <p> {{this.order.equipment_replaced}}</p> -->
                                </div>
                                <div class="equipments-header">
                                    <p><strong>{{$t('message.labourCost')}}</strong></p>
                                    <!-- <p>{{this.order.sub_equipment_replaced}}</p> -->
                                </div>
                                <div class="equipments-header">
                                    <p><strong>{{$t('message.transportedBy')}}</strong></p>
                                    <p>{{this.order.transportationName}}</p>
                                </div>
                                <div class="equipments-header">
                                    <p><strong>{{$t('message.transportationCost')}}</strong></p>
                                    <p> {{this.order.transportation_cost}}</p>
                                </div>
                                <div class="equipments-header">
                                    <p><strong>{{$t('message.tollGateCost')}}</strong></p>
                                    <p> {{this.order.toll_gate_cost}}</p>
                                </div>
                                <div class="equipments-header">
                                    <p><strong>{{$t('message.additionalNotes')}}</strong></p>
                                    <p> {{this.order.additional_note}}</p>
                                </div>
                                <div class="equipments-header">
                                    <p><strong>{{$t('message.attachments')}}</strong></p>
                                </div>

                                <div class="uploaded-files">
                                    <div v-for="(file) in Files" :key="file.id"
                                        class="file-item col-md-3">
                                        <div style="text-align: center;">
                                            <img :src="file.attachment_url" class="uploaded-image" @click="openInNewTab(file.attachment_url,'image')" />
                                            <!-- <div class="limit-name">{{ file.name }}</div> -->
                                        </div>
                                        <div>
                                            <b-icon class="download-icon" @click="downloadFile(file.attachment_url, file.id)"
                                                icon="download" aria-hidden="true"></b-icon>
                                        </div>
                                        
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                    </div>
                </b-tab>
            </b-tabs>
        </div>
        <div v-else>
            <div class="text-center p-4" style="width: 100%;">
                <img :src= "require('../assets/not-found.svg')" alt="404 Photo" style="width:500px;">
                <p class="mb-0 filter-result"> {{ $t('message.noMatchingResults') }} "{{ id }}".</p>
            </div>
        </div>
    </div>
</template>

<script>
import axiosInstance from '../axios-config';

export default {
    name: 'ShowDetails',
    props: ['id'], // التأكد من أن الخاصية id تم تمريرها بشكل صحيح
    data() {
        return {
            order:{
                order_status: null,
                equipment_replaced: null,
            },
            items: [
                { Status: 'Family Code', Used: 1,   New: 542 },
                { Status: 'Equip Family', Used: 2,  New: 'Fuel Tank & Pipework' },
                { Status: 'Type Code', Used: 3,  New: 'Fuel Tank & Pipework' },
                { Status: 'Equip Type', Used: 4, New: 'Fuel Tank & Pipework' },
                { Status: 'Equip Serial', Used: 5,  New: 'Fuel Tank & Pipework' },
                { Status: 'Equip Model', Used: 5,  New: 'Fuel Tank' },
                { Status: 'Equip Manufacturer', Used: 5,  New: 'Fuel Tank' },
                { Status: 'Cost', Used: 5,  New: 'Fuel Tank' },
            ],
            fields: [
                'Status',
                { key: 'Used', label: 'Used' },
                { key: 'New', label: 'New' }
            ],
            Files:[],
            downloadedFiles: new Set(), // تخزين الملفات التي تم تنزيلها
            isLoading: true, 
        };

    },

    computed: {
        isRTL() {
            return this.$i18n.locale === 'ar';
        },
        statusName() {
            const statusMap = {
                0: 'pending',
                1: 'started',
                2: 'in progress before finish TTD',
                3: 'completed',
                4: 'decline',
                5: 'need approval',
                6: 'in process after finish TTD',
            };
            return statusMap[this.order.order_status] || 'unknown status';
        },
        equipmentReplacedText() {
            const statusMap = {
                0: 'No',
                1: 'Yes',
                '-1': 'Not Available',
            };
            return statusMap[this.order.equipment_replaced] || 'Unknown';
        },
    },
    created() {
        this.fetchData();
        this.fetchFiles();
    },
    methods: {
        googleMapsLink(location) {
            const encodedLocation = encodeURIComponent(location);
            return `https://www.google.com/maps/search/?api=1&query=${encodedLocation}`;
        },

        async fetchData() {
            try {
                const formData = new FormData();
                formData.append('order_id', this.id);
                const response = await axiosInstance.post('company/select_order_by_id.php', formData);

                console.log(response.data);
                if (response.data.status === "true") {
                    this.order = response.data.output;
                    console.log('order = ', this.order);
                    this.isLoading = false;
                }
            } catch (error) {
                console.error('Error logging in:', error)
                this.$bvToast.toast('An error occurred while trying to get data', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                    toaster: 'b-toaster-bottom-left', // Position of the toast
                    // autoHideDelay: 1000,
                });
                this.isLoading = false;
            }
        },
        async fetchFiles() {
            try {
                const formData = new FormData();
                formData.append('order_id', this.id);
                const response = await axiosInstance.post('technical/select_order_attachments.php', formData);

                console.log(response.data);
                if (response.data.status === "true") {
                    this.Files = response.data.output;
                    console.log('order = ', this.order);
                }
            } catch (error) {
                console.error('Error logging in:', error)
                this.$bvToast.toast('An error occurred while trying to get files', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                    toaster: 'b-toaster-bottom-left', // Position of the toast
                    // autoHideDelay: 1000,
                });
            }
        },
        openInNewTab(url, type) {
            console.log('type:',type);
            console.log('url:',url);
            
            window.open(url, '_blank');

        },
        downloadFile(url, fileId) {
            if (this.downloadedFiles.has(fileId)) {
                // عرض رسالة تفيد بأن الملف تم تنزيله مسبقًا
                this.$bvToast.toast('You have already downloaded this file.', {
                    title: 'Warning',
                    variant: 'warning',
                    solid: true,
                    toaster: 'b-toaster-bottom-left', // Position of the toast
                    // autoHideDelay: 1000,
                });
                return; // منع التنزيل
            }

            // جلب الملف
            fetch(url)
            .then(response => response.blob()) // تحويل الاستجابة إلى Blob
                .then(blob => {
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob); // إنشاء رابط URL مؤقت للملف
                    link.download = `file_${fileId}`; // تعيين اسم افتراضي للملف
                    document.body.appendChild(link); // إضافة الرابط إلى DOM
                    link.click(); // النقر على الرابط لتنزيل الملف
                    document.body.removeChild(link); // إزالة الرابط بعد التنزيل

                    // إضافة الملف إلى مجموعة الملفات التي تم تنزيلها
                    this.downloadedFiles.add(fileId);

                    
                    this.$bvToast.toast('File downloaded successfully.', {
                        title: 'Success',
                        variant: 'success',
                        solid: true,
                        toaster: 'b-toaster-bottom-left', // Position of the toast
                        // autoHideDelay: 1000,
                    });
                })
                .catch(error => {
                    this.$bvToast.toast('An error occurred while trying to download the file.', {
                        title: 'Error',
                        variant: 'error',
                        solid: true,
                        toaster: 'b-toaster-bottom-left', // Position of the toast
                        // autoHideDelay: 1000,
                    });
                    
                    console.error('Error downloading the file:', error);
                });
        },
        showDetails(itemId){
            console.log(111111111);
            // this.$router.push('show-details/' + itemId);
            this.$router.push(`/orders/${itemId}/details`);
            // /orders/:id/details
        }
    }

};
</script>

<style scoped>
.custom-container-orders {
    /* Custom styles for the container */
}

.back-btn {
    color: #003A6E;
    font-family: 'Somar-Bold';
    font-size: 28px;
    display: flex;
    align-items: center;
}

.back-btn:hover {
    text-decoration: none;
    color: #003A6E;
}

.icon-slider {
    font-size: 15px !important;
    color: #FFD448 !important;
    margin-top: 0px !important;
}

.detais-header p strong {
    color: #2B5FA8;
    font-family: 'Somar-Bold';
    font-size: 25px;
}
.equipments-header p strong {
    color: #2B5FA8;
    font-family: 'Somar-Bold';
    font-size: 25px;
}

.detais-response p {
    color: #7F98BB;
    font-family: 'Somar-Medium';
    font-size: 25px;
}
.equipments-header p {
    color: #7F98BB;
    font-family: 'Somar-Medium';
    font-size: 25px;
}
p{
    /* margin-bottom: 5px; */
}
.equipments-header{
    display: flex;
    justify-content: space-between;
}
.repalced-equipment , .spare-parts{
    color: #fff;
    font-family: 'Somar-Bold';
    font-size: 22px;
    text-align: center
}
.repalced-equipment{
    background-color:#5B9BF5;
}
.spare-parts{
    background-color:#2B5FA8;
}
.btn-close {
  font-size: smaller;
}

.uploaded-files {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 15px;
  justify-content: left;
}


.file-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #dee2e6;
  padding: 2px;
  border-radius: 5px;
  background-color: #f8f9fa;
  /* width: 22%; */
  justify-content: center;
  min-height: 165px;
}

/* .limit-name {
  text-align: center;
  width: 150px;
  overflow: hidden;
} */

.uploaded-image {
  width: 100px;
  /* width: -webkit-fill-available; */
  height: 90px;
  object-fit: cover;
  border-radius: 5px;
  /* margin-bottom: 5px; */
}
.uploaded-image:hover{
    cursor: pointer;
}

.file-item progress {
  width: 100%;
  margin: 5px 0;
  height: 5px;
  border-radius: 50px;
}

.file-item button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  padding: initial;
}
.download-icon {
    position: absolute;
    left: 11px;
    top: 9px;
    color: #FF0000;
    font-size: 20px;
    cursor: pointer;
}
</style>

