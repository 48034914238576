<template>
  <div>
    <div class="slide">
      <div class="bg-img">
        <div class="slide-content">
          <div>
            <h2 class="slide-header">{{$t('message.ourOrders')}}</h2>
          </div>
          <div>
            <h5 class="slide-h5">{{$t('message.home')}}</h5>
            <b-icon :icon="isRTL ? 'chevron-double-left' : 'chevron-double-right'" class="icon-slider"></b-icon>
            <h5 class="slide-h5">{{$t('message.orders')}}</h5>
          </div>
        </div>
      </div>
    </div>

    <div class="container custom-container-new-orders mt-5">
      <l-map :zoom="zoom" :center="center" style="height: 500px; width: 100%;" @update:center="updateLatLngFromMap"  @dblclick="moveMarkerToDoubleClick">
        <l-tile-layer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"></l-tile-layer>
        <l-marker :lat-lng="markerPosition" :draggable="true" @update:lat-lng="updateLatLngFromMarker"></l-marker>
      </l-map>
      
      <div class="mt-5 new-order-input">
        <b-form-group :label="$t('message.customerName')">
          <v-select :dir="direction" v-model="selectedCustomer" :options="customerOptions" label="name"
            :placeholder="$t('message.searchOrEnterNewCustomer')" @search="debouncedFetchCustomerData" @input="onCustomerSelect"
            @create="onCustomerCreate" :filterable="true" :taggable="true" :clearable="true"
            :createLabel="'Add new customer'" class="custom-bg" />
        </b-form-group>

        <!-- Additional input fields for customer details -->
        <!-- <b-form-group label="Customer Name">
          <b-form-input class="custom-bg" v-model="newCustomerName" :disabled="!isNewCustomer" placeholder="Enter customer name"></b-form-input>
        </b-form-group> -->



        <b-form-group :label="$t('message.latitude')">
          <b-form-input id="latitude" class="custom-bg" v-model="lat" @change="updateMarkerPosition"></b-form-input>
        </b-form-group>

        <b-form-group :label="$t('message.longitude')">
          <b-form-input id="longitude" class="custom-bg" v-model="lng" @change="updateMarkerPosition"></b-form-input>
        </b-form-group>
        <b-form-group :label="$t('message.mapUrl')">
          <b-form-input id="mapUrl" class="custom-bg" v-model="mapUrl"></b-form-input>
        </b-form-group>

        <b-form-group :label="$t('message.clientNumber')">
          <b-form-input v-model="clientNumber" class="custom-bg" type="number"></b-form-input>
        </b-form-group>

        <b-form-group :label="$t('message.services')">
          <b-form-select @change="fetchTechniciansData()" class="custom-bg" v-model="selectedService">
            <option v-for="service in services" :value="service.id" :key="service.id">
              {{ service.name }}
            </option>
          </b-form-select>
        </b-form-group>

        <b-form-group :label="$t('message.Technician')">
          <div class="d-flex align-items-center">

            <b-form-select class="custom-bg" v-model="selectedTechnician">
              <option v-for="technician in technicians" :key="technician.id" :value="technician">
                {{ technician.name }}
              </option>
            </b-form-select>
            <!-- Spinner يظهر فقط أثناء جلب البيانات -->
            <b-spinner v-if="isLoading" small class="ml-2" label="Loading..."></b-spinner>
          </div>
        </b-form-group>



        <b-form-group :label="$t('message.Supervisor')">
          <b-form-select class="custom-bg" v-model="selectedSupervisor">
            <option v-for="supervisor in supervisors" :key="supervisor.id" :value="supervisor">{{ supervisor.name }}
            </option>
          </b-form-select>
        </b-form-group>

        <b-form-group :label="$t('message.contactDateTime')">
          <b-form-input type="datetime-local" class="custom-bg" v-model="contactDateTime"></b-form-input>
        </b-form-group>

        <b-button class="newOrder-btn" :disabled="!isFormValid" @click="addNewOrder">{{ $t('message.addNewOrder') }}</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import VSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import axiosInstance from '../axios-config';
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { mapGetters } from 'vuex'
import debounce from 'lodash.debounce';



delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default {
  props: ['id'],
  components: {
    LMap,
    LTileLayer,
    LMarker,
    VSelect,
  },
  data() {
    return {
      center: [30.03105542654023, 31.223144531250004],
      markerPosition: [30.03105542654023, 31.223144531250004],
      lat: '',
      lng: '',
      mapUrl: '',
      zoom: 7,
      customers: [],
      selectedCustomer: null,
      newCustomerName: '',
      isNewCustomer: true,
      customerOptions: [],
      services: [],
      clientNumber: '',
      selectedService: '',
      selectedTechnician: null,
      technicians: [],
      selectedSupervisor: null,
      supervisors: [],
      contactDateTime: this.formatDateTime(new Date()),
      isLoading: false,  // this controls the loading indicator

    };
  },
  computed: {
    ...mapGetters(['getUser']),
    company_id() {
      return this.getUser; // استخدام الـ getter لجلب بيانات المستخدم
    },
    isRTL() {
      return this.$i18n.locale === 'ar';
    },
    isFormValid() {
      // Check if clientNumber is exactly 11 digits
      const isClientNumberValid = this.clientNumber && this.clientNumber.length === 11;

      return (
        isClientNumberValid &&
        this.lat &&
        this.lng &&
        this.mapUrl &&
        this.selectedService &&
        this.selectedTechnician &&
        this.selectedSupervisor &&
        this.contactDateTime
      );
    },

    direction() {
      return this.$i18n.locale === 'ar' ? 'rtl' : 'ltr';
    },
  },
  methods: {
    updateLatLngFromMarker(latLng) {
      this.lat = latLng.lat;
      this.lng = latLng.lng;
      this.markerPosition = [this.lat, this.lng];
    },
    moveMarkerToDoubleClick(event) {
      // Update marker position with the double-click coordinates
      this.markerPosition = [event.latlng.lat, event.latlng.lng];
    },
    updateLatLngFromMap(center) {
      this.center = center;
    },
    updateMarkerPosition() {
      this.markerPosition = [parseFloat(this.lat), parseFloat(this.lng)];
      this.center = [...this.markerPosition];
    },
    formatDateTime(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // شهور تبدأ من 0 لذا نضيف 1
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },

    // onCustomerInput(selected) {
    //   if (selected) {
    //     this.newCustomerName = selected.name;
    //     this.isNewCustomer = false;
    //     this.lat = selected.latitude;
    //     this.lng = selected.latitude;
    //     this.mapUrl = selected.map_url;
    //     this.clientNumber = selected.phone;
    //     this.updateMarkerPosition();
    //   } else {
    //     this.isNewCustomer = true;
    //   }
    // },
    // onCustomerCreate(name) {
    //   this.selectedCustomer.id = 0;
    // },
    splitDateTime(dateTimeString) {
      const [date, time] = dateTimeString.split(' ');
      return { date, time };
    },
    async addNewOrder() {
      if (this.id) {
        try {
        const formData = new FormData();
        formData.append('company_id', this.company_id.id);
        formData.append('admin_id', this.company_id.id);
        if (this.isNewCustomer === true) {
        formData.append('customer_name', this.selectedCustomer);
        }else{
          formData.append('customer_name', this.selectedCustomer.name);
        }
        formData.append('customer_phone', this.clientNumber);
        formData.append('map_url', this.mapUrl);
        formData.append('longitude', this.lng);
        formData.append('latitude', this.lat);
        formData.append('service_id', this.selectedService);
        formData.append('technical_id', this.selectedTechnician.id);
        formData.append('order_status', 0);
        if (this.isNewCustomer === true) {
          formData.append('site_id', 0);
        }else{
          formData.append('site_id', this.selectedCustomer.id);
        }
        const { date, time } = this.splitDateTime(this.contactDateTime);
        formData.append('call_date', date);
        formData.append('call_time', time);
        formData.append('is_pm_order', 1);
        formData.append('equipment_pm_id', this.id);

        const response = await axiosInstance.post('company/insert_order.php', formData);

        if (response.data.status === "true") {
          // Clear all input fields after a successful submission
          this.clearInputFields();

          // Show a success message
          this.$bvToast.toast('Order successfully inserted!', {
            title: 'Success',
            variant: 'success',
            solid: true,
            toaster: 'b-toaster-bottom-left',
          });
        } else {
          // Show an error message if the submission fails
          this.$bvToast.toast('An error occurred while submitting the order. Please try again.', {
            title: 'Error',
            variant: 'danger',
            solid: true,
            toaster: 'b-toaster-bottom-left',
          });
        }
      } catch (error) {
        console.error('Error logging in:', error)
        this.$bvToast.toast('An error occurred while trying to get data', {
          title: 'Error',
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-bottom-left', // Position of the toast
          // autoHideDelay: 1000,
        });
      }
        
      }else{
        try {
        const formData = new FormData();
        formData.append('company_id', this.company_id.id);
        formData.append('admin_id', this.company_id.id);
        if (this.isNewCustomer === true) {
        formData.append('customer_name', this.selectedCustomer);
        }else{
          formData.append('customer_name', this.selectedCustomer.name);
        }
        formData.append('customer_phone', this.clientNumber);
        formData.append('map_url', this.mapUrl);
        formData.append('longitude', this.lng);
        formData.append('latitude', this.lat);
        formData.append('service_id', this.selectedService);
        formData.append('technical_id', this.selectedTechnician.id);
        formData.append('order_status', 0);
        if (this.isNewCustomer === true) {
          formData.append('site_id', 0);
        }else{
          formData.append('site_id', this.selectedCustomer.id);
        }
        const { date, time } = this.splitDateTime(this.contactDateTime);
        formData.append('call_date', date);
        formData.append('call_time', time);
        formData.append('is_pm_order', 0);
        formData.append('equipment_pm_id', 0);

        const response = await axiosInstance.post('company/insert_order.php', formData);

        if (response.data.status === "true") {
          // Clear all input fields after a successful submission
          this.clearInputFields();

          // Show a success message
          this.$bvToast.toast('Order successfully inserted!', {
            title: 'Success',
            variant: 'success',
            solid: true,
            toaster: 'b-toaster-bottom-left',
          });
        } else {
          // Show an error message if the submission fails
          this.$bvToast.toast('An error occurred while submitting the order. Please try again.', {
            title: 'Error',
            variant: 'danger',
            solid: true,
            toaster: 'b-toaster-bottom-left',
          });
        }
      } catch (error) {
        console.error('Error logging in:', error)
        this.$bvToast.toast('An error occurred while trying to get data', {
          title: 'Error',
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-bottom-left', // Position of the toast
          // autoHideDelay: 1000,
        });
      } 
      }
    },
    clearInputFields() {
      this.selectedCustomer = null;
      this.clientNumber = '';
      this.lat = '';
      this.lng = '';
      this.mapUrl = '';
      this.isNewCustomer = true;
      this.selectedService = '';
      this.selectedTechnician = null;
      this.selectedSupervisor = null;
    },
    fetchCustomerData(searchKey) {
      const formData = new FormData();
      formData.append('search_key', searchKey);
      formData.append('company_id', this.company_id.id);

      axiosInstance
        .post('search_sites.php', formData)
        .then(response => {
          if (response.data.status === "true") {
        if (response.data.output.length === 0) {
          // No matching customer found, it's a new customer
          this.isNewCustomer = true;
          this.$bvToast.toast('This is a new customer. Please fill in the details.', {
            title: 'New Customer',
            variant: 'info',
            solid: true,
            toaster: 'b-toaster-bottom-left',
          });
        } else {
          // Customers found, handle selection logic here
          this.isNewCustomer = false;
          this.customerOptions = response.data.output;
        }
      }
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          this.$bvToast.toast('An error occurred while trying to get data', {
            title: 'Error',
            variant: 'danger',
            solid: true,
            toaster: 'b-toaster-bottom-left',
          });
        });
    },
    debouncedFetchCustomerData: debounce(function (searchKey) {
      if (searchKey) {
        this.fetchCustomerData(searchKey);
      }
    }, 2000), // 2-second delay

    onCustomerSelect(selectedOption) {
  // Check if the selected option exists in the customerOptions list
  const isExistingCustomer = this.customerOptions.some(
    (option) => option.name === selectedOption?.name
  );

  if (isExistingCustomer) {
    
    // If an existing customer is selected, populate the input fields
    this.newCustomerName = selectedOption.name;
    this.clientNumber = selectedOption.phone;
    this.lat = selectedOption.latitude;
    this.lng = selectedOption.longitude;
    this.mapUrl = selectedOption.map_url;
    this.isNewCustomer = false;
    this.updateMarkerPosition();
  } else {
    // If a new customer is being created, clear the input fields
    
    this.isNewCustomer = true;
    this.newCustomerName = selectedOption?.name || '';
    this.clientNumber = '';
    // this.lat = '';
    // this.lng = '';
    this.mapUrl = '';
  }
},

    onCustomerCreate(newCustomerName) {
      this.isNewCustomer = true;
      this.newCustomerName = newCustomerName;
      // Reset other fields for a new customer
      this.clientNumber = '';
      this.lat = '';
      this.lng = '';
      this.mapUrl = '';
    },
    async fetchServicesData() {
      try {
        const formData = new FormData();
        formData.append('company_id', this.company_id.id);
        const response = await axiosInstance.post('company/select_services.php', formData);

        if (response.data.status === "true") {
          this.services = response.data.output;
        }
      } catch (error) {
        console.error('Error logging in:', error)
        this.$bvToast.toast('An error occurred while trying to get data', {
          title: 'Error',
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-bottom-left', // Position of the toast
          // autoHideDelay: 1000,
        });
      }
    },
    async fetchTechniciansData() {
      this.isLoading = true;  // Start loading
      try {
        const formData = new FormData();
        formData.append('company_id', this.company_id.id);
        formData.append('service_id', this.selectedService);
        formData.append('offset', 0);
        const response = await axiosInstance.post('company/select_employees_service.php', formData);

        if (response.data.status === "true") {
          this.technicians = response.data.output;
        } else {
          this.technicians = []; // Clear the technicians if data fetch fails
        }
      } catch (error) {
        console.error('Error logging in:', error)
        this.$bvToast.toast('An error occurred while trying to get data', {
          title: 'Error',
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-bottom-left', // Position of the toast
          // autoHideDelay: 1000,
        });
      } finally {
        // setTimeout(() => {
        this.isLoading = false;  // Stop loading  
        // }, 3000);

      }
    },
    async fetchSupervisorsData() {
      try {
        const formData = new FormData();
        formData.append('company_id', this.company_id.id);
        formData.append('offset', 0);
        const response = await axiosInstance.post('company/select_admins.php', formData);

        if (response.data.status === "true") {
          this.supervisors = response.data.output;
        }
      } catch (error) {
        console.error('Error logging in:', error)
        this.$bvToast.toast('An error occurred while trying to get data', {
          title: 'Error',
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-bottom-left', // Position of the toast
          // autoHideDelay: 1000,
        });
      }
    },
  },
  mounted() {
    // this.fetchCustomerData();
    this.fetchServicesData();
    this.fetchSupervisorsData();
  },
};
</script>

<style scoped>
.new-order-input {
  border: 1px solid #E7E7E7;
  border-radius: 20px;
  padding: 20px;
}

.newOrder-btn {
  width: -webkit-fill-available;
  border-radius: 10px;
  margin-top: 20px;
  cursor: pointer;
  background-image: linear-gradient(90deg, #FFCA1F 0%, #FFD44A 100%);
  color: #fff;
  font-size: 21px;
  font-family: 'Somar-bold';
  border-width: 0px;
  outline: none !important;
  border: none !important;
}
.newOrder-btn:focus{
  box-shadow: none !important;
}
</style>