<template>
    <div class="Companies">
      <div class="slide">
        <div class="bg-img">
          <div class="slide-content">
            <h2 class="slide-header"> Companies</h2>
            <p></p>
          </div>
        </div>
      </div>
      <h1></h1>
  
    </div>
  </template>
  
  <script>
  // import paginationTable from '../components/table.vue'
  // import card from '../components/card.vue'
  export default {
    name: 'CompaniesView',
    components: {
      // paginationTable,
      // card
    },
    data(){
      return{
        
      }
    },
    methods: {
      
    }
  }
  </script>
  
  <style scoped>
  
  .bg-img {
    height: 205px;
    background-image: url(../assets/slide-photo.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex; /* استخدم Flexbox */
    justify-content: center; /* محاذاة أفقيًا في المنتصف */
    align-items: center; /* محاذاة رأسيًا في المنتصف */
    text-align: center; /* محاذاة النصوص في المنتصف */
  }
  .slide-content{
    color: #2274BD;
  }
  .slide-content .slide-header{
    text-transform: uppercase;
  }
  </style>
  