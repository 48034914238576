<template>
  <b-navbar toggleable="lg" type="dark" class="py-3 navbar fixed-top" fixed="top" >
    <!-- Navbar Brand -->
    <!-- <b-navbar-brand href="#">LOGO</b-navbar-brand> -->

    <!-- Navbar Toggle Button -->
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <!-- Language and User Role Dropdown (visible on mobile next to the toggle) -->
    <div class="d-lg-none language-userRole">
      <b-nav-item-dropdown right>
        <template #button-content>
          <img class="avatar avatar-xss avatar-circle"
               :src="currentLanguage === 'ar' ? require('../assets/ar.svg') : require('../assets/us.svg')"
               :alt="currentLanguage === 'ar' ? 'Saudi Arabia flag' : 'US flag'">
        </template>
        <b-dropdown-item @click="switchLanguage('ar')">
          <img :src="require('../assets/ar.svg')" width="30" height="30" alt="Arabic" class="avatar-circle"> العربية
        </b-dropdown-item>
        <b-dropdown-item @click="switchLanguage('en')">
          <img :src="require('../assets/us.svg')" width="30" height="30" alt="English" class="avatar-circle"> English
        </b-dropdown-item>
      </b-nav-item-dropdown>
      
      <b-nav-item-dropdown right :class="[{ 'dropdown-menu-rtl': isRTL, 'dropdown-menu-ltr': !isRTL }]" v-if="this.isAuthenticated">
        <template #button-content>
          <span class="user-role">{{user.name}}</span> 
          <img :src="require('@/assets/Group 21188.png')" width="30" height="30" alt="userRole" class="">
        </template>
        <b-dropdown-item @click="goToProfile()">{{$t('message.profile')}}</b-dropdown-item>
        <!-- <b-dropdown-item href="#">Settings</b-dropdown-item> -->
        <b-dropdown-item v-if="isAuthenticated" @click="logout">{{$t('message.logout')}}</b-dropdown-item>
      </b-nav-item-dropdown>
      <div class="d-flex" style="align-items: center;" v-if="!this.isAuthenticated">
          <b-button @click="goToLogin()" class="login-btn" variant="outline-warning">Login</b-button>
        </div>
    </div>

    <!-- Navbar Collapse -->
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item @click="check()">
          <router-link to="/" exact>{{$t('message.home')}}</router-link>
        </b-nav-item>
        <b-nav-item>
          <router-link to="/about-us">{{$t('message.about')}}</router-link>
        </b-nav-item>
        <b-nav-item @click="check()">
          <router-link to="/orders">{{$t('message.orders')}}</router-link>
        </b-nav-item>
        <b-nav-item @click="check()">
          <router-link to="/Requests">{{$t('message.requests')}}</router-link>
        </b-nav-item>
        <!-- <b-nav-item disabled>
          <router-link to="/Companies">Companies</router-link>
        </b-nav-item> -->
        <b-nav-item @click="check()">
          <router-link to="/Employees">{{$t('message.employees')}}</router-link>
        </b-nav-item>
        <b-nav-item @click="check()" >
          <router-link to="/locations">{{$t('message.locations')}}</router-link>
        </b-nav-item>
      </b-navbar-nav>

      <!-- Language and User Role Dropdown (visible on larger screens) -->
      <b-navbar-nav :class="[{ 'mr-auto': isRTL, 'ml-auto': !isRTL }]" class="d-none d-lg-flex">
        <b-nav-item-dropdown style="margin-top: 7px;" right :class="[{ 'dropdown-menu-rtl': isRTL, 'dropdown-menu-ltr': !isRTL }]">
          <template #button-content>
            <img class="avatar avatar-xss avatar-circle"
                 :src="currentLanguage === 'ar' ? require('../assets/ar.svg') : require('../assets/us.svg')"
                 :alt="currentLanguage === 'ar' ? 'Saudi Arabia flag' : 'US flag'">
          </template>
          <b-dropdown-item @click="switchLanguage('ar')">
            <img :src="require('../assets/ar.svg')" width="30" height="30" alt="Arabic" class="avatar-circle"> العربية
          </b-dropdown-item>
          <b-dropdown-item @click="switchLanguage('en')">
            <img :src="require('../assets/us.svg')" width="30" height="30" alt="English" class="avatar-circle"> English
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown right :class="[{ 'dropdown-menu-rtl': isRTL, 'dropdown-menu-ltr': !isRTL }]" v-if="this.isAuthenticated">
          <template #button-content>
            <span class="user-role">{{user.name}}</span> 
            <img :src="require('@/assets/Group 21188.png')" width="30" height="30" alt="userRole" class="">
          </template>
          <b-dropdown-item @click="goToProfile()">{{$t('message.profile')}}</b-dropdown-item>
        <!-- <b-dropdown-item href="#">Settings</b-dropdown-item> -->
        <b-dropdown-item v-if="isAuthenticated" @click="logout">{{$t('message.logout')}}</b-dropdown-item>
        </b-nav-item-dropdown>
        
        <div class="d-flex" style="align-items: center;" v-if="!this.isAuthenticated">
          <b-button @click="goToLogin()" class="login-btn" variant="outline-warning">Login</b-button>
        </div>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>


<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: "CustomNavbar",
  data(){
    return{
      currentLanguage: this.$i18n.locale,
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated','getUser']),
    isRTL() {
      return this.$i18n.locale === 'ar';
    },
    user() {
      return this.getUser; // استخدام الـ getter لجلب بيانات المستخدم
    }
  },
  methods: {
    // في component الخاص بـ navbar
    ...mapActions(['logout']),
    async logout() {
      try {
        await this.$store.dispatch('logout'); // حذف بيانات المستخدم من Vuex
        // مسح userId من localStorage
        localStorage.removeItem('userId');
        
        this.$bvToast.toast('You have successfully logged out', {
          title: 'successfully logged out',
          variant: 'success',
          solid: true,
          toaster: 'b-toaster-bottom-left',
          headerClass:this.toastClass,
          bodyClass: this.toastClass,
          // autoHideDelay: 1000,
        });
        setTimeout(() => {
          this.$router.push('/about-us'); // التحويل إلى صفحة تسجيل الدخول
        }, 2000);
      } catch (error) {
        console.error('Error logging out:', error);
      }
    },
    check(){
      if (!this.isAuthenticated) {
        // إظهار رسالة تنبيه إذا لم يكن مسجلًا
      this.$bvToast.toast('Please log in to access this page', {
          title: 'Login Required',
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-bottom-left',
        });
        setTimeout(() => {
          // توجيه المستخدم إلى صفحة تسجيل الدخول بعد ظهور الرسالة
          this.$router.push('/login');
        }, 2000);
      }
      
    },
    goToLogin(){
      this.$router.push('/login');
    },
    goToProfile(){
      this.$router.push('/profile');
    },
    handleClick(){
      this.$router.push('/Companies');
    },
    switchLanguage(language) {
      this.currentLanguage = language;
      this.$i18n.locale = language;
    }
  }
};
</script>

<style scoped>
.navbar {
  background-color: #2274BD; /* لون الخلفية الأزرق */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.navbar-brand {
  font-weight: bold;
  font-size: 1.5rem;
}
/* 
.nav-item.active {
  background-color: #0056b3; 
  color: #FFD448 !important;
} */

.navbar-nav .router-link-active {
  color: #FFD448 !important;
  border: none;
  outline: none;
  text-decoration: none;
}
.navbar-nav a {
  color: #FFFFFF;
  font-size: 22px;
}
.user-role{
  font-size: 22px;
  padding-left: 5px;
  padding-right: 5px;
  color: #fff !important;
}
.navbar-nav a:hover {
  text-decoration: none;
  border: none;
  outline: none;
  color: #FFD448 !important;
}


.nav-link {
  color: #fff;
  /* font-weight: bold; */
  /* font-family: somar; */
  font-size: medium;
}

.dropdown-menu {
  background-color: #0062cc; /* لون خلفية القائمة المنسدلة */
  color: #fff;
}

.dropdown-item {
  color: #fff;
}

.dropdown-item:hover {
  background-color: #0056b3; /* لون الخلفية عند التمرير */
}
.avatar-circle{
  border-radius: 50%;
    width: 1.3rem;
    height: 1.3rem;
}
.avatar{
  margin-top: -3px;
}
.language-userRole{
  display: flex;
  align-items: center;
  border: 0px transparent;  
}
.language-userRole li{
  list-style: none;
  border: 0px transparent;
}

.login-btn{
  width: 120px;
  /* height: 50px; */
  color: #fff;
  font-family: 'Somar-Bold';
  /* font-size: 25px;
  margin: 5px; */
}
@media (max-width: 768px) {
  #nav-collapse {
  background-color:#2274BD !important ;
  padding: 20px;
  z-index: 1001;
  border-radius: 8px;
  margin-top: 4px;
  transition: all .2s ease-in-out;
}
.login-btn{
  width: 90px;
  /* height: 40px;
  font-size: 20px;
  margin: 5px; */
}
}

</style>
