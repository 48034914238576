<template>
    <div>
        <div class="slide mb-5">
        <div class="bg-img">
            <div class="slide-content">
            <div>
                <h2 class="slide-header"> {{$t('message.locations')}}</h2>
            </div>
            <div>
                <h5 class="slide-h5">{{$t('message.home')}}</h5>
                <b-icon :icon="isRTL ? 'chevron-double-left' : 'chevron-double-right'" class="icon-slider"></b-icon>
                <h5 class="slide-h5"> {{$t('message.locations')}}</h5>
            </div>
            </div>
        </div>
        </div>
        <div class="custom-container-location mt-5">
        <div id="map" style="height: 500px;display: flex;justify-content: center;width: 100%;"></div>
        </div>
    </div>
</template>

<script>
import L from "leaflet";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            map: null,
            markers: {},
            reloadInterval: null,
        };
    },
    mounted() {
        this.initializeMap();
        this.loadEmployeeData();

        this.reloadInterval = setInterval(() => {
            this.loadEmployeeData();
        }, 3000);
    },
    beforeDestroy() {
        if (this.reloadInterval) {
            clearInterval(this.reloadInterval);
        }
    },
    computed: {
        ...mapGetters(['getUser']),
        company_id() {
        return this.getUser; // استخدام الـ getter لجلب بيانات المستخدم
        },
        isRTL() {
            return this.$i18n.locale === 'ar';
        },
    },
    methods: {
        initializeMap() {
            this.map = L.map("map").setView([30.0444, 31.2357], 9);

            L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
                maxZoom: 19
            }).addTo(this.map);
        },
        loadEmployeeData() {
            const employeeRef = firebase.database().ref("technicals");
            // console.log('employeeRef=' , employeeRef);

            employeeRef.on("value", (snapshot) => {
                const data = snapshot.val();
                console.log('employeeRef=', data);

                this.updateMarkers(data);
            });
        },
        updateMarkers(technicals) {
            Object.keys(technicals).forEach((key) => {
                const employee = technicals[key];

                // تصفية الموظفين الذين لديهم technicalCompanyId = 1 فقط
                console.log(this.company_id.id);
                console.log('employee',employee.technicalCompanyId);
                
                
                if (employee.technicalCompanyId == this.company_id.id) {
                    console.log('ok in');
                    
                    if (this.markers[key]) {
                        // تحريك الـ marker
                        this.markers[key].setLatLng([employee.technicalLatitude, employee.technicalLongitude]);
                    } else {
                        // إضافة marker جديد
                        const marker = L.marker([employee.technicalLatitude, employee.technicalLongitude]).addTo(this.map);
                        marker.bindPopup(`<b>${employee.technicalName}</b>`);
                        this.markers[key] = marker;
                    }
                }
            });
        }
    }
};
</script>

<style scoped>
#map {
    width: 80%;
    height: 500px;
    border-radius: 20px;
}
.custom-container-location {
  padding-left: 200px;
  padding-right: 200px;
}

@media (max-width: 576px) {
  .custom-container-location {
      padding-left: 15px;
      padding-right: 15px;

  }
}
</style>