import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // user: null,
    user: JSON.parse(localStorage.getItem('user')) || null, // جلب البيانات من localStorage
    token: null,
    // isAuthenticated: false,
    isAuthenticated: !!localStorage.getItem('userId'),

  },
  mutations: {
    setUser(state, userData) {
      state.user = userData
      state.token = userData.token
      state.isAuthenticated = true
      localStorage.setItem('userId', userData.id) // تخزين userId في localStorage
      localStorage.setItem('user', JSON.stringify(userData)); // تخزين بيانات المستخدم
    },
    clearUser(state) {
      state.user = null
      state.token = null
      state.isAuthenticated = false
      localStorage.removeItem('userId');
      localStorage.removeItem('user');
    }
  },
  actions: {
    login({ commit }, userData) {
      commit('setUser', userData)
    },
    logout({ commit }) {
      commit('clearUser')
    }
  },
  getters: {
    isAuthenticated: state => state.isAuthenticated,
    getUser: state => state.user,
    // getUserId: state => state.userId
    getToken: state => state.token
  }
})
