<template>  
    <div class="container custom-container">
        <h2 class="text-center request-header"><span>Our</span> Location</h2>
    <b-row class="align-items-center">
      <b-col cols="6" class="d-flex align-items-center mb-5">
        <h3 class="filter-text">{{ $t('message.filter') }}</h3>
        <b-form-input
          class="filter-input"
          v-model="selectedFilter"
          :placeholder="$t('message.adminName')"
          :options="filters"
          @change="filterLocations"
        ></b-form-input>
      </b-col>
    </b-row>
      <div id="map" style="height: 500px;"></div>  
    </div>  
  </template>  
  
  <script>  
  import { BFormGroup, BFormSelect } from 'bootstrap-vue';  
  import 'leaflet/dist/leaflet.css';  
  import L from 'leaflet';  
  
  export default {  
    components: {  
      BFormGroup,  
      BFormSelect,  
    },  
    data() {  
      return {  
        map: null,  
        locations: [  
          { name: 'Location 1', lat: 19.4326, lng: -99.1332, type: 'Company A' },  
          { name: 'Location 2', lat: 19.4326, lng: -99.1333, type: 'Company B' },  
          // أضف المزيد من المواقع هنا  
        ],  
        filteredLocations: [],  
        selectedFilter: null,  
        filters: [  
          { value: null, text: 'All Companies' },  
          { value: 'Company A', text: 'Company A' },  
          { value: 'Company B', text: 'Company B' },  
        ],  
      };  
    },  
    mounted() {  
      this.initMap();  
      this.filteredLocations = this.locations;  
    },  
    methods: {  
      initMap() {  
        this.map = L.map('map').setView([19.4326, -99.1332], 13);  
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {  
          maxZoom: 19,  
        }).addTo(this.map);  
        this.addMarkers();  
      },  
      addMarkers() {  
        this.filteredLocations.forEach(location => {  
          L.marker([location.lat, location.lng])  
            .addTo(this.map)  
            .bindPopup(location.name);  
        });  
      },  
      filterLocations() {  
        if (this.selectedFilter) {  
          this.filteredLocations = this.locations.filter(location => location.type === this.selectedFilter);  
        } else {  
          this.filteredLocations = this.locations;  
        }  
        this.map.eachLayer(layer => {  
          if (layer instanceof L.Marker) {  
            this.map.removeLayer(layer);  
          }  
        });  
        this.addMarkers();  
      },  
    },  
  };  
  </script>  
  
  <style scoped>  
  .custom-container{
  padding-left: 60px !important;
  padding-right: 60px !important;
}
  #map {  
    height: 500px;  
  }  
  .filter-input {
  width: 150px !important;
  border-radius: 20px !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);

}

.filter-text {
  color: #45566D;
  font-family: 'Somar-Bold';
  font-size: 28px;
  margin-right: 10px;
}
.request-header {
  font-family: 'Somar-Bold';
  font-size: 30px;
  color: #2274BD;
  text-transform: uppercase;
}

.request-header span {
  color: #FFD448;
}
  </style>